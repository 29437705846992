import React, { useState, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import { 
  BrowserRouter as Router, 
  Navigate,
  useParams,
  useNavigate, 
  Routes,
  Route, 
  Link,
  useLoaderData,
 } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import ApprovalList from './ApprovalList';
import DateTime from '../utils/DateTime';
import useSWR, { preload } from 'swr';
import { localAxios } from '../api';

export function loader() {
  // job of this function is to get the data we want in a component before it renders
  // the job is also to identify if we are logged in before accessing a protected route
  // this would typically be a fetch request inside here, but for now, return a string
  // I can't use const { data, error, isLoading } = useSWR('/me', localAxios) because that's a React Hook
  // Also, useEffect is a React hook I think.  This replaces those.
  
  //const { approvals, error, isLoading, mutate } = useSWR('/api/approvals', localAxios);

  return "Approve default data goes here, including an array of visitors to approve.";
  // return getUserData();  // return the promise
}    

// 1. useSWR('me') => returns {data: undefined} => newData ? newData : cachedData
 

function Approve() {

  const checkRef = useRef(null);
  const RUBY_HILL = 1, MCCOY_COVE = 2, LONE_TREE = 3, GRANITE_CREEK = 4;
  const siteMap = new Map();
  siteMap.set("Ruby Hill", RUBY_HILL);
  siteMap.set("McCoy Cove", MCCOY_COVE);
  siteMap.set("Lone Tree", LONE_TREE);
  siteMap.set("Granite Creek", GRANITE_CREEK);

  const params = useParams();
  const [siteID, setSiteID] = useState(siteMap.get(params.siteName));

  console.log("siteID is ", siteID);
  console.log("map value is ", siteMap.get(params.siteName));


  /**********************************************************************************************
   * The useSWR hook accepts two primary arguments: "key" (a unique identifier for the data, 
   * usually the API URL) and "fetcher" (an asynchronous function that retrieves the data based on
   * the provided key).
   * Key:
   *    Can be a string, array, or a function that generates the key dynamically.
   *    This key is directly passed to the "fetcher" function as an argument.
   * Fetcher:
   *    An async function that takes the "key" as an argument and returns the fetched data.
   *    Can be a custom function or the built-in fetch API.
   *********************************************************************************************/
  
  // The way I was doing this:
  const { data, error, isLoading, isValidating, mutate } = useSWR(`/api/approve?siteID=${siteID}`, localAxios)

  console.log(`error is ${error}`,!!data)
  console.log(`isLoading is ${isLoading}`,!!data)
  console.log(`isValidating is ${isValidating}`,!!data)


  const [todayDate, setTodayDate] = useState(new Date());

  /*
  console.log(`setting approvals here.`,data?.data.approvals)
  const [approvals, setApprovals] = useState(data?.data.approvals);
  console.log(`Just set approvals.`)
  */
  //  The first value is ${approvals[0]?.first_name}`)
  const navigate = useNavigate();

  //const approveData = useLoaderData();
  //console.log(`approveData?.approvals is ${approveData?.approvals}`)
  //console.log(`approveData[0] is ${approveData[0]}`);
  
  //setApprovals(data?.data.approvals)
  /*
  [currentCount, setCurrentCount] = useState(count)

  useEffect(
    setCurrentCount(count)
  ),[count]

  // Per Adam W, research the following hooks as homework.  I'll also add useRef
  useEffect
  useState
  useMemo
  useCallback
  useRef
  */

  console.log(`data is ${data}`);
  console.log(`data?.data is ${data?.data}`);
  console.log(`data?.data.loggedIn is ${data?.data.loggedIn}`);
  
  //console.log(`data?.data.approvals[0].id is ${data?.data.approvals[0].id}`);


  /*
  console.log(`approveData is ${approveData}`);
  console.log(`approveData?.data is ${data?.data}`);
  console.log(`approveData?.approvals[0]?.first_name is ${approveData?.approvals[0]?.first_name}`);

  approvalsRecord?.records[0]  // actually holds the data currently
  */

  
  useEffect(() => {
    // useEffect hooks outside resource into the React component.  If we use JavaScript, there is no reason to use the hook.
    const site = params.siteName
    if (site) {
      
      //console.log(`About to set siteName as ${siteName} in local storage.`)
      localStorage.setItem('siteName', site); // Store in localStorage
      console.log(`Just set siteName as ${site} in local storage.`)
      localStorage.setItem('screen', 'approve');

    } else {
      const storedSiteName = localStorage.getItem('siteName'); // Retrieve from localStorage
      /*
      if (storedSiteName) {
        setSiteName(storedSiteName); 
      }*/
      console.log(`Just got siteName ${storedSiteName} from local storage.`)
    }
  }, [params]);
  

  const approvals=data?.data.approvals

  

  const postData = async (event) => {

    event.preventDefault();
  
    let approveForm = event.target.form;

    // Get both date and time
    setTodayDate(new Date());

    let formData = new FormData();
  
    //const jsonString = JSON.stringify(form);

    /*array.forEach(element => {
    });*/


    /** TODO: 
     * 1. I'm currently passing form data in, but it needs a separate name for each field for each id.
     * 2. So, create an array of objects
     * 3. Put the id, email, datetime, and checked value in each object
     * 4. Figure out how that is best done with a form to keep the current convention, rather than sending in the header
     **/

    //formData.append("phone", ackForm.phone.value);

    approvals.forEach(
      // Create a callback arrow function that adds additional properties to each array object that was approved
      // If the checkbox with that id is checked, then
      // The approve email and datetime and a 1 flag.
      approval => {
        if (document.getElementById(approval.id).checked) {
          //alert("It was checked.");
          //alert(`document.getElementById(approval.id).checked is ${document.getElementById(approval.id).checked}`);
          approval.approve_datetime = todayDate.toISOString();
          approval.approver_email = data?.data.email;
          approval.approve_flag = 1;
          console.log("this approval record includes ", approval)
        } else {
          //alert("It was not checked.");
          //alert(`document.getElementById(approval.id).checked is ${document.getElementById(approval.id).checked}`);
        }
      }
    );

    console.log(`approvals is[0] ${approvals[0]}`)
    console.log(`approvals is[0]?.first_name ${approvals[0]?.first_name}`)

    /*
    for (let i = 0; i < approvals.length; i++) {
      const approval = approvals[i];
      for (const key in approval) {
        formData.append(`approvals[${i}][${key}]`, approval[key]); 
      }
    }
    */

    formData.append('example', JSON.stringify(approvals));
    console.log("The array in full is ", JSON.stringify(approvals))

    
    if (document.getElementsByName("approve_acknowledge_flag").checked) {
      formData.append("approve_acknowledge_flag", "1");
    }

    formData.append("siteID", siteID.toString());

    //console.log("jsonString ", jsonString);
    console.log("formData is ", formData);
  
    // Now create a form object with key/value pairs
    let formDataObj = Object.fromEntries(formData.entries());
    console.log("formDataObj is ", formDataObj);
  
    let formJSON = JSON.stringify(formDataObj);
    console.log("formJSON is ", formJSON);
  
    return localAxios.post('/api/approve', formJSON).then(response=>{
        console.log("processing after response")
        console.log(response)
        
        if(response) {
          navigate("/approved");
        };
    }).catch((error) => {
        console.log(error)
    })
    

  }//postData


  if (!data && isValidating) {    
    return <p>Loading...</p> 
  }

  // I used to check only data?.data.loggedIn.  But the problem is that is only part of what I'm waiting for!
  if (data && !isValidating) {
  //if (isLoggedIn) {
    //console.log("Reached isLoggedIn yay.");
    console.log("data includes ", data.data.loggedIn)
    console.log("data includes ", data.data.first_name)
    //console.log(`approvals.length is ${approvals.length}`)

    return (
      
      <div style={{ textAlign: 'center'}}>
        
        <form>
        
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                  <ScreenHeader message={"Visitor Training Tracker"}/>
                  </td>
                <td /> 
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                    <h1>Site: {params.siteName}</h1>
                  </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>

                    {
                    data?.data.approvals ? (
                      <p>You have <b>{data?.data.approvals.length > 0 ? data?.data.approvals.length : "no"}</b> visitors to approve at <b>{params.siteName}</b></p>
                    ) : (
                      <p>No visitors to approve at this time.</p>
                    )
                    } 
                  </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td colSpan={3}> 
                  
                  {data ? (
                    <div>
                      <ApprovalList approvals={data?.data.approvals}/>
                    </div>
                  ) : (
                    <p>Loading visitors...</p>
                  )}
                  
                </td>
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                <td style={{maxWidth: 850}}>
                  <br/>
                  <br/>
                    <b>
                      {approvals && approvals.length > 0 && (
                        <Display message={`I approve the above visitors.`}/>
                      )}
                    </b>
                </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <br/>
            {approvals && approvals.length > 0 && (                      
              <label>
                I Agree: <input type="checkbox" name="approve_acknowledge_flag" />
              </label>
            )}
          <br/><br/>
          {approvals && approvals.length > 0 && (
            <button onClick={ postData } >Submit</button>
          )}
          {/*<button type="submit" onSubmit={ navToSuccess }>Submit</button>*/}
        </form>
        <br/>
        <br/>
          {/*message={`Submit`}*/}
      </div>
    );

  }
  
  if (isValidating) {
    return <div>Validating data... </div>;
  }

  if (!data?.data.loggedIn) {

    console.log(`For some reason, the cookie is not detected.`)
    return <Navigate to="/login" />;
  
  }

  if (error) {
    // Optionally render an error state
    console.log(`handling error in Approve page.`)
    return <p>Error: {error.message}</p>;
  }

} // function     


export default Approve;

