import React, { useRef, useEffect, useState } from 'react';
import { Link } from "react-router-dom"; 
import { NavDropdown, Table } from 'react-bootstrap';
import { useActionState } from 'react';
import useSWR, { preload } from 'swr';
import { localAxios } from '../api';



const ApprovalList = ({ approvals }) => {

  console.log(`approvals is ${approvals}`)
    
  const [checkedItems, setCheckedItems] = useState({});
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (id) => {
    setCheckedItems({
      ...checkedItems,
      [id]: !checkedItems[id],
    });
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const newCheckedItems = {};
    if (!selectAll) {
      approvals.forEach((approval) => {
        newCheckedItems[approval.id] = true;
      });
    }
    setCheckedItems(newCheckedItems);
  };
  
  const styles = {
    backgroundColor: "hsl(0, 0%, 22%)",
    color: "gold",
    padding: "10px 20px",
    borderRadius: "5px",
    border: "none",
    cursor: "pointer",
    width: "130px"
  }

  return (
    <div>
      <Table align='center' cellPadding={5}>
        <tbody>
          <tr>
            <td align="left">
              {approvals && approvals.length > 0 && (
                <input 
                  type="checkbox" 
                  id="selectAllCheckbox" 
                  onChange={handleSelectAllChange}
                />
              )}
              {approvals && approvals.length > 0 && (
                <label htmlFor="select_all">Select All</label>
              )}
            </td>
          </tr>
          <tr>
            <td align="left">

              {
                approvals ? (
                  approvals.map((approval, index) => (
                    <div key={index}>
                      <input 
                        type="checkbox" 
                        checked={checkedItems[approval.id] || false}
                        onChange={() => handleCheckboxChange(approval.id)}
                        id={`${approval.id}`} />
                      <label htmlFor={`${approval.id}`}>{approval.first_name} {approval.last_name}</label>
                    </div>
                  ))
                ) : (
                  <p>Loading approvals...</p>
                )
              }

            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default ApprovalList;

