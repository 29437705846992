import React from 'react';
import { Table } from 'react-bootstrap';
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import useSWR from 'swr';
import { localAxios } from '../api';


function Approved() {

  const { data, error, isLoading } = useSWR('/api/me', localAxios) 

  return (
    <div style={{ textAlign: 'center'}}>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
                <td style={{maxWidth: 850}}>
                <ScreenHeader message={"Visitor Training Tracker"}/>
                </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <Table align='center' striped bordered hover cellPadding={5}>
          <tbody>
            <tr>
              <td />
              <td style={{maxWidth: 850}}>
                <br/>
                <br/>
                  <Display message={`Your visitors have been approved! The submission has been recorded.`}/>
                <br/>
                  
                <br/>
                  You may now close this browser page.
              </td>
              <td />
            </tr>
          </tbody>
        </Table>
        <br/><br/>
        
      <br/>
      <br/>
        {/*message={`Submit`}*/}
    </div>
  );
}

export default Approved;

