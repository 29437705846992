import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { 
  BrowserRouter as Router, 
  Navigate,
  useParams,
  useNavigate, 
  Routes,
  Route, 
  Link,
  useLoaderData,
  useSearchParams,
 } from "react-router-dom"; 
import ScreenHeader from '../ScreenHeader';
import Display from '../Display';
import CenteredTextContainer from '../CenteredTextContainer';
import Button from '../Button';
import AcknowledgeForm from '../AcknowledgeForm';
import DateTime from '../utils/DateTime';
import useSWR, { preload } from 'swr';
import { localAxios } from '../api';

export function loader() {
  // job of this function is to get the data we want in a component before it renders
  // the job is also to identify if we are logged in before accessing a protected route
  // this would typically be a fetch request inside here, but for now, return a string
  // I can't use const { data, error, isLoading } = useSWR('/me', localAxios) because that's a React Hook
  // Also, useEffect is a React hook I think.  This replaces those.
  
  return "Acknowledge default data goes here, including first name, last name, email, and phone.";
  // return getUserData();  // return the promise
}


/*
const navToSuccess = () => {
  const navigate = useNavigate();
  navigate('/success');
}
*/

// 1. useSWR('me') => returns {data: undefined} => newData ? newData : cachedData
 

function Acknowledge() {

  const { data, error, isLoading, isValidating, mutate } = useSWR('/api/me', localAxios) // undefined but loading even while page mounts
  //const [searchParams] = useSearchParams();

  console.log(`isLoading is ${isLoading}`,!!data)
  console.log(`isValidating is ${isValidating}`,!!data)

  const RUBY_HILL = 1, MCCOY_COVE = 2, LONE_TREE = 3, GRANITE_CREEK = 4;
  const siteMap = new Map();
  siteMap.set("Ruby Hill", RUBY_HILL);
  siteMap.set("McCoy Cove", MCCOY_COVE);
  siteMap.set("Lone Tree", LONE_TREE);
  siteMap.set("Granite Creek", GRANITE_CREEK);


  const [todayDate, setTodayDate] = useState(new Date());
  const navigate = useNavigate();
  const params = useParams();
  
  //const [siteName, setSiteName] = useState(params.siteName);
  const [siteID, setSiteID] = useState(siteMap.get(params.siteName));

  console.log("siteID is ", siteID);
  console.log("map value is ", siteMap.get(params.siteName));

  /*
  const acknowledgeData = useLoaderData();
  console.log(acknowledgeData);
  */


  useEffect(() => {
    // useEffect hooks outside resource into the React component.  If we use JavaScript, there is no reason to use the hook.
    const site = params.siteName
    if (site) {
      
      //console.log(`About to set siteName as ${siteName} in local storage.`)
      localStorage.setItem('siteName', site); // Store in localStorage
      console.log(`Just set siteName as ${site} in local storage.`)
      localStorage.setItem('screen', 'acknowledge');
    } else {
      const storedSiteName = localStorage.getItem('siteName'); // Retrieve from localStorage
      
      /*
      if (storedSiteName) {
        setSiteName(storedSiteName); 
      }*/
      console.log(`Just got siteName ${storedSiteName} from local storage.`)
    }
  }, [params]);


  /*
  const clearSiteName = () => {
    localStorage.removeItem('siteName');
    setSiteName(null);
  }*/


  const postData = (event) => {

    event.preventDefault();
  
    /*
    var now = moment().format(); // Format the moment as a string
    var momentInput = document.getElementById("currentMoment");
    momentInput.value = now;
    */
    //var vttForm = new FormData(document.getElementById("form"));
   
    let ackForm = event.target.form;
 
    // Get both date and time
    setTodayDate(new Date());


    let formData = new FormData();
  
    //const jsonString = JSON.stringify(form);
  
    formData.append("first_name", ackForm.first_name.value);
    formData.append("last_name", ackForm.last_name.value);
    formData.append("email", ackForm.email.value);
    formData.append("phone", ackForm.phone.value);
    formData.append("organization", ackForm.org.value);
    formData.append("acknowledge_flag", "1");
    formData.append("recorded_datetime", todayDate.toISOString());
    formData.append("siteID", siteID.toString());
  
    //console.log("jsonString ", jsonString);
    console.log("formData is ", formData);
  
    // Now create a form object with key/value pairs
    let formDataObj = Object.fromEntries(formData.entries());
    console.log("formDataObj is ", formDataObj);
  
    let formJSON = JSON.stringify(formDataObj);
    console.log("formJSON is ", formJSON);
  
    return localAxios.post('/api/acknowledge', formJSON).then(response=>{
        console.log("processing after response")
        console.log(response)
        
        if(response) {
          navigate("/success");
        };
    }).catch((error) => {
        console.log(error)
    })
  
  }//postData

  if (isLoading || isValidating) return <p>Loading...</p>
  if (data?.data.loggedIn) {
    //if (isLoggedIn) {
        //console.log("Reached isLoggedIn yay.");
    console.log("data includes ", data.data.loggedIn)
    console.log("data includes ", data.data.first_name)

    return (
      <div style={{ textAlign: 'center'}}>
        
        <form>
        {/*<form class="form" id="vttForm" method="post" action="https://visitor-trainer-cqckd6ced3a0bgch.centralus-01.azurewebsites.net/api/records">*/}
        {/*<form id="vttForm" method="post" action={"https://webhook.site/5fbb042c-2885-4d73-8e85-a87f25eccb05">*/}
        
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                  <ScreenHeader message={"Visitor Training Tracker"}/>
                  </td>
                <td /> 
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                    <h1>Site: {params.siteName}</h1>
                  </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                    <p>This is visit <b>{data.data.visit_count + 1}</b>. After this visit, you will have <b>{5 - (data.data.visit_count + 1)} {(5 - (data.data.visit_count + 1)) > 1 ? "more visits" : "more visit"}</b> remaining.</p>
                  </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                  <td style={{maxWidth: 850}}>
                    <CenteredTextContainer/>
                  </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td colSpan={3}> 
                  <div>
                    <AcknowledgeForm />
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
          <Table align='center' striped bordered hover cellPadding={5}>
            <tbody>
              <tr>
                <td />
                <td style={{maxWidth: 850}}>
                  <br/>
                  <br/>
                    <Display message={`I have read and do understand the above statement completely. I will follow the directions given.`}/>
                </td>
                <td />
              </tr>
            </tbody>
          </Table>
          <br/>
          <label>
            I Agree: <input type="checkbox" name="acknowledge_flag" />
          </label>
          <br/><br/>
          
          <button onClick={ postData } >Submit</button>
          {/*<button type="submit" onSubmit={ navToSuccess }>Submit</button>*/}
        </form>
        <br/>
        <br/>
          {/*message={`Submit`}*/}
      </div>
    );
  
  } else {

    console.log(`For some reason, the cookie is not detected.`)
    return <Navigate to="/login" />;
  
  }

} // function     


export default Acknowledge;

