import React, { useState, useEffect } from "react";
import { Navigate, useNavigate, useParams, Link, useSearchParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import Display from '../Display';
import ScreenHeader from '../ScreenHeader';
// Axios cleanly and more simply handles data fetches
import Axios from 'axios'
// use cookies to keep the user logged in and recognized
import {Cookies} from 'react-cookie'
// useSWR used for loading data and keeping it up-to-date
import useSWR from 'swr';
import { localAxios } from "../api";

// 1. login & register
// 2. save the responses (token)
// 3. load the saved token when we make an outgoing api call

//TODO
// 4. clear the saved token to logout


function Login(props) { 

    // useSWR, he started to implement here:
    // see 1:58:40 in first recording
    const { data, error, isLoading, mutate } = useSWR('/api/me', localAxios)
    // 1. valid
    // 2. invalid

    const navigate = useNavigate();
    
    //const [todayDate, setTodayDate] = useState(new Date());
    const [searchParams] = useSearchParams();
    //const [siteName, setSiteName] = useState(props.siteName.length>0 ? props.siteName : undefined);
    //const [siteName, setSiteName] = useState();

    

    /*
    useEffect(() => {
        //const site = searchParams.get('siteName');
        if (siteName) {
            setSiteName(siteName);
            localStorage.setItem('siteName', siteName); // Store in localStorage
        } else {
        
        const storedSiteName = localStorage.getItem('siteName'); // Retrieve from localStorage
        if (storedSiteName) {
            setSiteName(storedSiteName);
        }
        //}

        if (data && siteName.length > 0) { 
            navigate(`/acknowledge/${siteName}`); 
        }
    }, [data]);
    */

    /*
    useEffect(() => {
        if (data) { 
            navigate(`/acknowledge/${siteName}`); 
        }
    },[data])
    */
    

    /*useEffect(() => {
        if (error) {
            // handle the error
        }
    },[error])*/

    const handleFormSubmit = (event) => {
        event.preventDefault();
    
        let form = event.target.form;
        //console.log("form: " + form);
    
        let formData = new FormData(form);
        console.log("formData is ", formData);

        const todayDate = new Date();
        formData.append("todayDate", todayDate.toISOString());
    
        // Now create a form object with key/value pairs
        let formDataObj = Object.fromEntries(formData.entries());
        console.log("formDataObj is ", formDataObj);

        let formJSON = JSON.stringify(formDataObj);
        console.log("formJSON is ", formJSON);

        return localAxios.post('/login', formJSON).then(response=>{
            console.log(response)
            console.log("just called localAxios");
            
            // Here is where I need to call this but it's a React hook so I can't
            //{ data, error, isLoading } = useSWR('/me', localAxios) 

            if(response) {
                //console.log("data is ", data)
                mutate()
                console.log("data is ", data?.data.last_name);
            };
            
        }).catch((error) => {
            console.log(error)
        }) 
    
    };//handleFormSubmit


    if (data?.data.loggedIn) {
        
        //if localStorage.getItem("siteName") then return <Navigate to={`/acknowledge/${localStorage.getItem("siteName")}`} /> otherwise SelectLocation

        console.log("Reached isLoggedIn yay.");
        //return <Navigate to={`/acknowledge/${siteName}`} />;
        if (localStorage.getItem("screen") === 'approve') {
            return <Navigate to={`/approve/${localStorage.getItem("siteName")}`} />;
        } else {
            return <Navigate to={`/acknowledge/${localStorage.getItem("siteName")}`} />;
        }
        /*
        const storedSiteName = localStorage.getItem('siteName'); // Retrieve from localStorage
        console.log(`storedSiteName = ${storedSiteName}`)
        if (storedSiteName) {
            setSiteName(storedSiteName);  
            console.log(`URL is /acknowledge/${siteName}`)
            return <Navigate to={`/acknowledge/${siteName}`} />;
        }
        */
         
    } else {

        return (
            
            <div style={{ textAlign: 'center'}}>

                <form>
                    <Table align='center' striped bordered hover cellPadding={2}>
                        <tbody>
                        <tr>
                            <td />
                            <td style={{maxWidth: 850}}>
                            <ScreenHeader message={"Visitor Training Tracker"}/>
                            </td>
                            <td />
                        </tr>
                        </tbody>
                    </Table>
                    <Table align='center' striped bordered hover cellPadding={1}>
                        <tbody>
                            <tr>
                                <td />
                                <td style={{maxWidth: 850}}>
                                    <h2>Please Login</h2>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </Table>
                    <Table align='center' striped bordered hover cellPadding={5}>
                        <tbody>
                            <tr>
                                <td />
                                <td style={{maxWidth: 850}}>
                                    <br/>
                                    <br/>
                                    <Display message={`Need to register?`}/>
                                    <Link to="/register" >Register here</Link>
                                </td>
                                <td />
                            </tr>
                        </tbody>
                    </Table>
                    <br />
                    <Table align='center' striped bordered hover cellPadding={5}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <div>
                                        <Table align='center' striped bordered hover cellPadding={5}>
                                            <tbody>
                                                <tr>
                                                    <td width={"40%"} align="right">
                                                        <label>
                                                            Email:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="text"
                                                            autoCapitalize="off"
                                                            //value={userEmail}
                                                            name="userEmail" 
                                                            //onChange={(e) => setUserEmail(e.target.value)}
                                                            //value={inputs.email || ""}
                                                            //onChange={handleChange}
                                                            width={"200px"}
                                                        />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width={"40%"} align="right">
                                                        <label>
                                                            Password:
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <input 
                                                            type="password"
                                                            name="password"
                                                            //onChange={(e) => setPassword(e.target.value)}
                                                            //value={inputs.last_name || ""}
                                                            //onChange={handleChange}
                                                            width={"200px"}
                                                        />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <br/>
                    <button onClick={handleFormSubmit}>Submit</button>
                </form>
                <br/>
                {/*message={`Submit`}*/}
            </div>

        )
    }

};

export default Login;



