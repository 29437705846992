import * as React from "react";
import { useState } from "react";
import Cookies from 'js-cookie';
import * as ReactDOM from "react-dom";
import { 
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  useLoaderData,
} from "react-router-dom";
import SelectLocation from './pages/SelectLocation';
import Acknowledge, { loader as acknowledgeLoader } from './pages/Acknowledge';
import Approve, { loader as approveLoader } from './pages/Approve';
import HomePage, { loader as homePageLoader } from './pages/HomePage';
import RegisterPage from './pages/RegisterPage';
import Success from './pages/Success';
import Approved from './pages/Approved';
import NotFoundPage from './pages/NotFoundPage';
import Login from './utils/Login';
/******************************************************************************************
 * “SWR” is derived from Stale-While-Revalidate, an HTTP cache invalidation strategy popularized by
 * HTTP RFC 5861. SWR is a strategy to first return the data from cache (stale), then send 
 * the fetch request (revalidate), and finally come with the up-to-date data.
 * With SWR, components will get a stream of data updates constantly and automatically. 
 * And the UI will be always fast and reactive.
 * from https://swr.vercel.app/
 *****************************************************************************************/
import { SWRConfig } from "swr";
import Axios from 'axios';
import { localAxios } from "./api";


// ... import other pages

const router = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    loader: homePageLoader,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/login',
    element: <Login />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/register',
    element: <RegisterPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/acknowledge/:siteName',
    element: <Acknowledge />,
    loader: acknowledgeLoader,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/approve/:siteName',
    element: <Approve />,
    loader: approveLoader,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/success',
    element: <Success />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/approved',
    element: <Approved />,
    errorElement: <NotFoundPage />,
  },
  {
    path: '/choose',
    element: <SelectLocation />,
    errorElement: <NotFoundPage />,
  }
  

]);

function App () {
  
  //const sessionData = Cookies.get("vtt_user_session_cookie"); // name of cookie
  /*
  const [cookieValue, setCookieValue] = useState(false) //Cookies.get("vtt_user_session_cookie");
  console.log("the cookie value is: ", cookieValue);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  console.log("isLoggedIn value is: ", isLoggedIn);
  */

  return (
    
      
    <SWRConfig value={{fetcher: localAxios, revalidateOnMount: true, shouldRetryOnError: false}}>
      <div className="app-wide">
      <RouterProvider router={router}>
      {/* RouterProvider is built using the React ContextAPI. */}
      {/* This defers the entry point to our application to React Router */}
      {/* The entire application should be able to listen for changes in the route. */}
      {/* Using the router, we can define which components get rendered at each path. */}
      

      
      </RouterProvider>
      </div>
    </SWRConfig>
  );
};

export default App;